.App{
  width:100%;
  height:100%;
  position: absolute;
  background-color: #333333;
}

html, body{
  background-color: #333333;
}

img.vcard-hd-image{
  position: absolute;
  object-fit: cover;
  width:100%;
  height:100%;
  transition: opacity 0.3s;
  opacity:0;
}


div.vcard-hd-cover{
  position: absolute;
  width:100%;
  height:100%;
  opacity:0.5;
  background-color: black;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}


.debugInfo{
display: flex;
align-items: center;
  position: absolute;
  width:auto;
  left:20px;
  top:20px;
  background:black;
  color:white;
  font-size: 18px !important;
  padding:10px;
  max-width: calc(100% - 60px) !important;
  overflow: hidden;
  border-radius: 5px;
}

.desktopWrapper{

  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #333333;
  color:white;
  text-align: center;
}

.desktopWrapper div {
  padding-left:20px;
  padding-right:20px;
}


.desktopWrapper svg{
  margin-bottom: 10px;
  display: block;

  
}

.moveright{

  margin-right: 10px;;
}

.spinern{

animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}